import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */
import ContactForm from '@blocks/forms/contact';
import TextColumns from '@components/text-columns/text-columns';
import ContactBox from '@components/contact-box/contact-box';

import GetinTouch from '@components/forms/get-in-touch';
import CareerForm from '@components/forms/career';

const HomeContact = (props) => {
    const [renderComponent, setRenderComponent] = useState(false);
    const data = useStaticQuery(graphql`
        query {
        avatar1: file(relativePath: { eq: "avatar1.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        avatar2: file(relativePath: { eq: "avatar2.png" }) {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    useEffect(() => {

        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => { 
            if (renderComponent === false) {
              setRenderComponent(true)
            }
          })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        }) 
    }, [])




    // console.log('data ==>', data)

    return (
        <>
        {renderComponent ?
        <>
        <div data-aos-offset="0" className="home-contact" id="section-bottom-form" data-aos="fade-up" data-aos-duration="1000">
            <TextColumns>
                <Col md="12" lg={5}>
                    <ReactMarkdown source={props.Content} escapeHtml={false}/>
                    <Row>
                    {props.data.map((data, key) => {
                    return <>

                        <Col lg="12" md="6">
                            <ContactBox name={data.Name} phone={data.Phone} position={data.Designation} link={data.Calendly_Link} email={data.Email} linkText="Book a Video Call" image={data.Image && data.Image.childImageSharp.fluid} />
                        </Col>
                    </>
                    })}
                    </Row>
                </Col>
                <Col md="12" lg={{
                    span: 6,
                    offset: 1
                }}>
                    <div className="content">
                        {props.formname !="careers" &&
                        <GetinTouch />
                        }
                        
                        {props.formname =="careers" &&
                        <CareerForm />
                        }
                        
                    </div>
                </Col>
            </TextColumns>
        </div>
        </> : "" }
        </>
    )    
}

export default HomeContact
