import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapMarkerImage from './map-marker-image';
import ReactMarkdown from "react-markdown/with-html"


const MapMarker = (props) => {
  // console.log('Inside function MapMarker');

  let html = props.html || '';
  // console.log('html : ' + html);

  const _markerImageShowFlag = props.markerImageShowFlag || 1;
  // console.log('_markerImageShowFlag : ' + _markerImageShowFlag);

  const _mapmarkerInfoBox = MapMarkerInfoBox(html, _markerImageShowFlag); 
  // console.log('_markerImageShowFlag : ' + _markerImageShowFlag);

  return (
    _mapmarkerInfoBox
  )
}
export default MapMarker;

export function MapMarkerInfoBox(html, flag, link) {
  // console.log('Inside function MapMarkerInfoBox');
  // console.log('html : ' + html);
  // console.log('flag : ' + flag);
  const _html = html || '';
  const _flag = flag || 0;
  // console.log('_html : ' + _html);
  // console.log('_flag : ' + _flag);
  // let _styles = '';
  /*
  if ( 0 === _flag ) {
    _styles = {
      backgroundColor: "#ec008d",
      color: "#fff"
    }
  }
  */
  return ReactDOMServer.renderToStaticMarkup(
      <div className="marker" /*style={_styles}*/>
        <MapMarkerImage show={_flag} />
        <div className="info-box">
        <div style={{color: "#5D9272",width: "200px",height:"120px"}}>          
          <ReactMarkdown source={_html} escapeHtml={false}/>
        </div>
        </div>
      </div>
    )
}